<template>
  <div>
    <template v-if="loadingPatient">
      <v-overlay
        :opacity="0.75"
        :value="loadingPatient"
        style="z-index: 30"
      >
        <h2>Loading Patient Data...</h2>
        <v-progress-circular
          class="centerButtons"
          :size="70"
          :width="7"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </v-overlay>
    </template>
    <template v-if="accountTier === 'Free' || accountTier === 'Analytics' || accountTier === ''">
      <v-layout
        row
        wrap
      >
        <h2>Need to upgrade your account to view this page</h2>
      </v-layout>
    </template>

    <pg-container
      v-if="accountTier === 'Pharmaceutical' || accountTier === 'Pro'"
      :key="refresh"
    >
      <div
        slot="title"
        style="position: relative"
      >
        Praluent dashboard
        <v-btn
          v-show="tab === 3"
          small
          color="success"
          style="position: absolute; right: 0; top: 0"
          type="button"
          @click.native="rePullPatientList()"
        >
          Refresh list
        </v-btn>
      </div>
      <div slot="content">
        <v-tabs
          v-model="tab"
          dark
          centered
          grow
          show-arrows
          height="60px"
        >
          <v-tab
            key="ontherapy"
            class="headline"
          >
            On therapy
          </v-tab>

          <v-tab
            key="candidates"
            class="headline"
          >
            Candidates
          </v-tab>
          <v-tab
            key="inprogress"
            class="headline"
          >
            In Progress
          </v-tab>
          <v-tab
            key="status"
            class="headline"
          >
            Status
          </v-tab>
          <!-- <v-tab key="completed"  class="headline">
                    Completed
                  </v-tab> -->
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item key="ontherapy">
            <pg-section>
              <!-- <div slot="header">Patients list</div> -->
              <div slot="content">
                <p class="subheading">
                  Below is a list of patients who are currently being treated with Praluent.
                </p>
                <p class="subheading">
                  These patients should be advised to have their LDL monitored and must maintain compliance to therapy.
                </p>
                <!-- <v-text-field
                  v-model="searchPatient"
                  append-icon="search"
                  label="Search patient name"
                  single-line
                  hide-details
                ></v-text-field> -->
                <v-data-table
                  :items="onTherapyList"
                  item-key="ID"
                  class="pa-0 mb-5 elevation-6"
                  :headers="patientsHeaders"
                  :loading="isDataPulling"
                  :options.sync="pagination"
                  :sort-by="['daysUntilDue']"
                  :sort-desc="['true']"
                >
                  <v-progress-linear
                    v-show="isDataPulling"
                    slot="progress"
                    :indeterminate="true"
                  ></v-progress-linear>
                  <!-- <template v-slot:item.onTherapyList="{ item }">
                    <strong
                      v-for="(onTherapy, index) in item['onTherapyList']"
                      :key="index"
                    >
                        {{ this.onTherapyList.RxDate }}
                    </strong>
                  </template> -->
                  <!-- <template slot="items" slot-scope="props">
                    <td align="left">
                      <strong>
                          {{ props.item["name"] }}
                      </strong>
                    </td>
                  </template>
                  <template slot="items" slot-scope="props">
                    <td align="left">
                      <strong>
                          {{ props.item["RxDate"] }}
                      </strong>
                    </td>
                  </template>
                  <template slot="items" slot-scope="props">
                    <td align="left">
                      <strong>
                          {{ props.item["Days"] }}
                      </strong>
                    </td>
                  </template> -->
                  <!-- <template>
                    {{
                      item["RxDate"] !== ""
                        ? $moment(item["RxDate"]).format("YYYY-MM-DD")
                        : ""
                    }}
                  </template>
                  <template>
                    {{
                      item["RxDate"] !== ""
                        ? $moment(item["RxDate"])
                            .add(item["Days"], "days")
                            .format("YYYY-MM-DD")
                        : ""
                    }}
                  </template> -->
                </v-data-table>
              </div>
            </pg-section>
          </v-tab-item>
          <v-tab-item key="candidates">
            <pg-section>
              <!-- <div slot="header">Candidates list</div> -->
              <div slot="content">
                <p class="subheading">
                  Below is a list of patients who may benefit from treatment with Praluent.
                </p>
                <p class="subheading">
                  Click the patient name for further assessment.
                </p>
                <v-btn
                  class="primary"
                  @click.native="newPatient"
                >
                  Enroll New Patient
                </v-btn>
                <v-text-field
                  v-model="searchPatient"
                  append-icon="search"
                  label="Search patient name"
                  single-line
                  hide-details
                ></v-text-field>
                <v-data-table
                  :items="candidatesList"
                  item-key="ID"
                  class="pa-0 mb-5 elevation-6"
                  :headers="candidatesHeaders"
                  :loading="isDataPulling"
                  :search="searchPatient"
                  :options.sync="pagination"
                  @click:row="selectCandidate"
                >
                  <v-progress-linear
                    v-show="isDataPulling"
                    slot="progress"
                    :indeterminate="true"
                  ></v-progress-linear>
                  <template
                    slot="items"
                    slot-scope="props"
                  >
                    <tr
                      :active="props.selected"
                      class="candidate-row"
                    >
                      <td align="left">
                        <strong>
                          {{ props.item['name'] }}
                        </strong>
                      </td>
                      <!-- <td align="left">
                                          <v-chip
                                            v-for="step in props.item['PROGRESS']"
                                            :key="step.id"
                                          >
                                            {{ step.label }}
                                            <v-icon v-if="step.done" color="green">check</v-icon>
                                          </v-chip>
                                        </td> -->
                      <!-- <td width="10%">{{props.item['HEALTH CARD NUMBER']}}</td> -->
                      <!-- <td>
                                          <strong v-for="(insurance, index) in props.item['InsuranceProvider']" :key="index">
                                            {{ `${index + 1}. ${insurance.name}.` }}
                                          </strong>
                                        </td> -->
                    </tr>
                  </template>
                  <template v-slot:item.InsuranceProvider="{ item }">
                    <strong
                      v-for="(insurance, index) in item['InsuranceProvider']"
                      :key="index"
                    >
                      {{ `${index + 1}. ${insurance.name}.` }}
                    </strong>
                  </template>
                  <v-alert
                    v-if="!isDataPulling"
                    slot="no-data"
                    :value="true"
                    color="info"
                  >
                    Sorry, nothing to display here...
                  </v-alert>
                </v-data-table>
              </div>
            </pg-section>
          </v-tab-item>

          <v-tab-item key="inprogress">
            <pg-section>
              <div slot="content">
                <p class="subheading">
                  Below is a list of patient forms that are currently in progress.
                </p>
                <p class="subheading">
                  Click the patient name for further assessment.
                </p>
                <v-btn
                  class="primary"
                  @click.native="newPatient"
                >
                  Enroll New Patient
                </v-btn>
                <v-text-field
                  v-model="searchPatient"
                  append-icon="search"
                  label="Search patient name"
                  single-line
                  hide-details
                ></v-text-field>
                <v-data-table
                  :items="inProgressList"
                  item-key="ID"
                  class="pa-0 mb-5 elevation-6"
                  :headers="inProgressHeaders"
                  :loading="isDataPulling"
                  :search="searchPatient"
                  :options.sync="pagination"
                  @click="selectCandidate"
                >
                  <v-progress-linear
                    v-show="isDataPulling"
                    slot="progress"
                    :indeterminate="true"
                  ></v-progress-linear>
                  <template v-slot:item.changes="{ item }">
                    <v-icon
                      class="mr-2"
                      @click="loadPatientProfile(item['stateId'] || item['_id'])"
                    >
                      {{ icons.mdiPencil }}
                    </v-icon>
                  </template>
                  <template v-slot:item.delete="{ item }">
                    <v-icon @click="deleteInProgressPraulentForm(item['_id'])">
                      {{ icons.mdiDelete }}
                    </v-icon>
                  </template>
                  <!-- <template slot="items" slot-scope="props">
                    <tr :active="props.selected" class="candidate-row">
                      <td align="left">
                        <strong>
                          {{ props.item["name"] }}
                        </strong>
                      </td> -->
                  <!-- <td align="left">
                                      <v-chip
                                        v-for="step in props.item['PROGRESS']"
                                        :key="step.id"
                                      >
                                        {{ step.label }}
                                        <v-icon v-if="step.done" color="green">check</v-icon>
                                      </v-chip>
                                    </td> -->
                  <!-- <td width="10%">{{props.item['HEALTH CARD NUMBER']}}</td> -->
                  <!-- <td>
                                      <strong v-for="(insurance, index) in props.item['InsuranceProvider']" :key="index">
                                        {{ `${index + 1}. ${insurance.name}.` }}
                                      </strong>
                                    </td> -->
                  <!-- </tr>
                  </template>
                  <template v-slot:item.InsuranceProvider="{ item }">
                    <strong
                      v-for="(insurance, index) in item['InsuranceProvider']"
                      :key="index"
                    >
                      {{ `${index + 1}. ${insurance.name}.` }}
                    </strong>
                  </template>
                  <v-alert
                    slot="no-data"
                    v-if="!isDataPulling"
                    :value="true"
                    color="info"
                  >
                    Sorry, nothing to display here...
                  </v-alert> -->
                </v-data-table>
              </div>
            </pg-section>
          </v-tab-item>

          <!-- <v-tab-item key="status">
            <pg-section>
              <div slot="content">
                <p class="subheading">
                  Below is a list of patient forms that are currently in
                  progress.
                </p>
                <p class="subheading">
                  Click to continue amending a patient's form.
                </p>
                <v-text-field
                  v-model="searchPatient"
                  append-icon="search"
                  label="Search patient name"
                  single-line
                  hide-details
                ></v-text-field>
                <v-data-table
                  :items="inProgressList"
                  item-key="_id"
                  class="pa-0 mb-5 elevation-6"
                  :headers="inProgressHeaders"
                  :search="searchPatient"
                  :options.sync="pagination"
                >
                  <template slot="items" slot-scope="props">
                    <tr :active="props.selected" class="patient-row">
                      <td width="35%" align="left">
                        <strong>
                          {{ props.item.state["FIRST NAME"] }}
                          {{ props.item.state["LAST NAME"] }}
                        </strong>
                      </td>
                      <td width="20%">
                        {{
                          $moment(props.item.state["DATE OF BIRTH"]).format(
                            "YYYY/MM/DD"
                          )
                        }}
                      </td>
                      <td width="20%">
                        {{
                          props.item.state["DATE"] !== ""
                            ? props.item.state["DATE"]
                            : $moment(props.item.state["DATE"]).format(
                                "YYYY-MM-DD"
                              )
                        }}
                      </td>
                      <td width="20%">
                        {{ props.item.state["lastSavedDate"] }}
                      </td>
                    </tr>
                  </template>
                  <template v-slot:item.changes="{ item }">
                    <v-icon
                      class="mr-2"
                      @click="
                        loadPatientProfile(item['patientID'], item['_id'])
                      "
                    >
                      mdi-pencil
                    </v-icon>
                  </template>
                  <template v-slot:item.delete="{ item, index }">
                    <v-icon
                      @click="deleteInProgressPraulentForm(item['_id'], index)"
                    >
                      mdi-delete
                    </v-icon>
                  </template>
                </v-data-table>
              </div>
            </pg-section>
          </v-tab-item> -->

          <v-tab-item key="actions">
            <pg-form-actions
              ref="actionsComp"
              :is-loading="isDataPulling"
              @processPdfForm="showDialog()"
              @patientTrackUpdated="rePullPatientList()"
            ></pg-form-actions>
          </v-tab-item>

          <!-- <v-tab-item key="completed">
                <pg-section>
                    <div slot="header">Candidates list</div>
                    <div slot="content">
                      <p class="subheading">
                          Below is a list of patients who may benefit from treatment with Praluent.
                        </p>
                        <p class="subheading">
                          Click the patient name for further assessment.
                        </p>
                        <v-text-field
                          v-model="searchPatient"
                          append-icon="search"
                          label="Search patient name"
                          single-line
                          hide-details
                        ></v-text-field>
                        <v-data-table
                            :items="patientCompletedList"
                            item-key="ID"
                            class="pa-0 mb-5 elevation-6"
                            :headers="completedHeaders"
                            :loading="isDataPulling"
                            :search="searchPatient"
                            >
                            <v-progress-linear slot="progress" v-show="isDataPulling" :indeterminate="true"></v-progress-linear>
                            <template slot="items" slot-scope="props">
                                <tr :active="props.selected" class="candidate-row">
                                    <td align="left">
                                      <strong>
                                        {{props.item['name']}}
                                      </strong>
                                    </td>
                                    <td align="left">
                                      <v-btn type="button" small flat color="primary" @click.native="downloadPdf(props.item, props.item['PROGRESS']['startedDateTime']['pdf'])">
                                        <v-icon color="primary">cloud_download</v-icon>
                                      </v-btn>
                                    </td>
                                    <td align="left">
                                      <v-btn type="button" small flat color="primary" @click.native="downloadPdf(props.item, props.item['PROGRESS']['trillium']['pdf'])">
                                        <v-icon color="primary">cloud_download</v-icon>
                                      </v-btn>
                                    </td>
                                    <td align="left">
                                      <v-btn type="button" small flat color="primary" @click.native="downloadPdf(props.item, props.item['PROGRESS']['coverage']['pdf'])">
                                        <v-icon color="primary">cloud_download</v-icon>
                                      </v-btn>
                                    </td>
                                    <td align="left">
                                      <v-btn type="button" small flat color="primary" @click.native="downloadPdf(props.item, props.item['PROGRESS']['eligibility']['pdf'])">
                                        <v-icon color="primary">cloud_download</v-icon>
                                      </v-btn>
                                    </td>
                                    <td width="10%">{{props.item['HEALTH CARD NUMBER']}}</td>
                                </tr>
                            </template>
                            <v-alert slot="no-data" v-if="!isDataPulling" :value="true" color="info">
                                Sorry, nothing to display here...
                            </v-alert>
                            <v-alert slot="no-results" :value="true" color="error" icon="warning">
                              Your search for "{{ searchPatient }}" found no results.
                            </v-alert>
                        </v-data-table>
                    </div>
                </pg-section>
              </v-tab-item> -->
        </v-tabs-items>
      </div>
    </pg-container>

    <v-dialog
      v-model="dialog"
      persistent
      max-width="290"
    >
      <v-card>
        <v-card-title class="headline text-xs-center">
          Processing...
        </v-card-title>
        <v-card-text class="text-xs-center">
          <v-progress-linear indeterminate></v-progress-linear>
        </v-card-text>
        <v-card-actions> </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
    mdiPencil, mdiDelete
} from '@mdi/js'
import Section from '@/components/common/ui/Layout/Section/Section.vue'
import { stateService } from '@/render/api/zarya'
import { uniqBy } from 'lodash'
import Container from '@/components/common/ui/Layout/Container/Container.vue'
import Actions from '@/views/praluent/components/actions.vue'
import query from '@/views/praluent/query/query'

export default {
    name: 'PraluentDashboad',
    components: {
        'pg-section': Section,
        'pg-container': Container,
        'pg-form-actions': Actions,
    },
    data() {
        return {
            dialog: false,
            tab: null,
            statusEnum: {
                COMPLETED: 'completed',
                INPROCESS: 'progress',
            },
            searchPatient: '',
            patientsHeaders: [
                {
                    text: 'Patient Name',
                    align: 'left',
                    sortable: false,
                    class: 'black--text',
                    width: '25%',
                    value: 'name',
                },
                {
                    text: 'Last RX Date',
                    sortable: false,
                    class: 'black--text',
                    width: '25%',
                    value: 'RxDate',
                },
                {
                    text: 'Next Dose Date',
                    sortable: false,
                    class: 'black--text',
                    width: '25%',
                    value: 'nextDoseDate',
                },
                {
                    text: 'Days until Due',
                    sortable: true,
                    class: 'black--text',
                    width: '25%',
                    value: 'daysUntilDue',
                },

                //         {
                //   text: "Next Dose Date",
                //   sortable: false,
                //   class: "black--text",
                //   width: "25%",
                //   value: "daysUntilDue",
                // },
            ],
            candidatesHeaders: [
                {
                    text: 'Patient Name',
                    align: 'left',
                    sortable: false,
                    class: 'black--text',
                    width: '20%',
                    value: 'name',
                },
                {
                    text: 'Insurance provider',
                    align: 'left',
                    sortable: false,
                    class: 'black--text',
                    width: '20%',
                    value: 'InsuranceProvider',
                },
            ],
            inProgressHeaders: [
                {
                    text: 'Patient First Name',
                    align: 'left',
                    sortable: false,
                    class: 'black--text',
                    width: '20%',
                    value: 'FirstName',
                },
                {
                    text: 'Patient Last Name',
                    align: 'left',
                    sortable: false,
                    class: 'black--text',
                    width: '20%',
                    value: 'LastName',
                },
                {
                    text: 'Patient Date of Birth',
                    align: 'left',
                    sortable: false,
                    class: 'black--text',
                    width: '15%',
                    value: 'DOB',
                },
                {
                    text: 'Status',
                    align: 'left',
                    sortable: false,
                    class: 'black--text',
                    width: '15%',
                    value: 'Status',
                },
                {
                    text: 'Last Updated',
                    align: 'left',
                    sortable: false,
                    class: 'black--text',
                    width: '10%',
                    value: 'modifiedAt',
                },
                {
                    text: 'Amend',
                    align: 'left',
                    sortable: false,
                    class: 'black--text',
                    width: '10%',
                    value: 'changes',
                },
                {
                    text: 'Delete',
                    align: 'left',
                    sortable: false,
                    class: 'black--text',
                    width: '10%',
                    value: 'delete',
                },
            ],
            completedHeaders: [
                {
                    text: 'Patient Name',
                    align: 'left',
                    sortable: false,
                    class: 'black--text',
                    width: '40%',
                    value: 'name',
                },
                {
                    text: 'Medication history',
                    align: 'center',
                    sortable: false,
                    class: 'black--text',
                    width: '15%',
                },
                {
                    text: 'Trillium',
                    align: 'center',
                    sortable: false,
                    class: 'black--text',
                    width: '15%',
                },
                {
                    text: 'Coverage',
                    align: 'center',
                    sortable: false,
                    class: 'black--text',
                    width: '15%',
                },
                {
                    text: 'Eligibility cretiria',
                    align: 'center',
                    sortable: false,
                    class: 'black--text',
                    width: '15%',
                },
            ],
            refresh: 0, // for loading from file
            isDataPulling: true,
            patientsList: [],
            onTherapyList: [],
            candidatesList: [],
            inProgressList: [],
            listPraluentProcesses: {},
            labResults: {},
            candidateStatus: {
                is: false,
                msg: 'FALSE',
            },
            pagination: {
                rowsPerPage: 15,
            },
            accountTier: this.$store.getters.PharmacyProfile.accountTier,
            loadingPatient: false,
            icons: {
                mdiPencil,
                mdiDelete,
            },
        }
    },
    computed: {
    // patientCompletedList(){
    //   return this.candidatesList.filter( (patient) => {
    //     return patient['PROCESS STATUS'] === this.statusEnum.COMPLETED;
    //   })
    // }
    },
    mounted() {
    // this.pullOnTherapyList();

        if (this.accountTier === 'Pro' || this.accountTier === 'Pharmaceutical') {
            this.$store.dispatch('setFormClass', 'praluentInsurance')

            // if a patient is already pre-filled by patient search
            if (this.$route.query.patient) {
                return true
            }
            this.pullPatientList()
            this.patientInProgressList()

            // this.pullOnTherapyList();
            if (this.$route.params.tab == '2') {
                this.tab = '2'
            }
        }
    },
    methods: {
    // row=row.status == "in-progress"
        async patientInProgressList() {
            const token = await this.$auth.getTokenSilently()
            this.inProgressList = (await stateService.getStateByFormType('praluentInsurance', this.$store.state.User.pharmacy._id, token))
                .filter(row => {
                    if (row.status == 'in-progress' && row.state.praluentPatient) {
                        return row
                    }
                })
                .map(row => ({
                    ...row.state.praluentPatient,
                    ...row,
                    FirstName: row.state.praluentPatient['FIRST NAME'],
                    LastName: row.state.praluentPatient['LAST NAME'],
                    DOB: this.moment(`${row.state.praluentPatient['DATE OF BIRTH']}`).format('YYYY-MM-DD'),

                    // date: row.carrierID
                    lastSavedDate: row.state.praluentPatient.lastSavedDate,
                    Status: row.status,
                }))
        },

        changeFilterName(row) {
            return {
                ...row.state.praluentPatient,
                ...row,
                FirstName: row.state.praluentPatient['FIRST NAME'],
                LastName: row.state.praluentPatient['LAST NAME'],
                DOB: this.moment(`${row.state.praluentPatient['DATE OF BIRTH']}`).format('YYYY-MM-DD'),

                // date: row.carrierID
                lastSavedDate: row.state.praluentPatient.lastSavedDate,
                Status: row.status,
            }
        },
        showDialog() {
            this.dialog = true
        },
        rePullPatientList() {
            this.dialog = true
            this.loadingPatient = true
            this.isDataPulling = true

            Promise.all([
                // call it from within the actionComponent
                this.$refs.actionsComp && this.$refs.actionsComp.getPatientList(),

                // TODO: disabled just to assist with performance of status per business
                // this.pullPatientList(),
                // this.patientInProgressList()
            ]).then(() => {
                this.dialog = false
                this.loadingPatient = false
                this.isDataPulling = false
            })
        },

        // pullOnTherapyList() {
        //   const DBTypeList = this.$store.getters.PharmacyProfile.DBType;
        //   return this.$root.$system.DBAdapterConn.DBQuery(
        //     query.candidates[DBTypeList].patientsOnReptha
        //   ).then((PatientIDs) => {
        //     this.onTherapyList = uniqBy(PatientIDs, "PatientID").map(
        //       (onTherapyList) => {
        //         return {
        //           ...onTherapyList,
        //           name: `${onTherapyList["FIRST NAME"]} ${onTherapyList["LAST NAME"]}`,
        //           RxDate: this.moment(`${onTherapyList["RxDate"]}`).format("YYYY-MM-DD"),
        //           nextDoseDate: this.moment(`${onTherapyList["RxDate"]}`).add(`${onTherapyList["Days"]}`, "days").format("YYYY-MM-DD"),
        //           today: this.moment().format("YYYY-MM-DD"),
        //           // // daysUntilDue: this.today.dif(this.nextDoseDate, 'days')
        //           // daysUntilDue: this.nextDoseDate.dif(this.today, "days", false)
        //         };
        //       }
        //     );
        //   });
        // },
        pullPatientList() {
            // make sure to set it back
            const { DBType } = this.$store.getters.PharmacyProfile

            // FIXME integrate other systems
            return (
                this.$root.$system.DBAdapterConn.DBQuery(query.candidates[DBType].patientsOnReptha)
                    .then(PatientIDs => {
                        this.onTherapyList = uniqBy(PatientIDs, 'PatientID').map(onTherapyList => ({
                            ...onTherapyList,
                            name: `${onTherapyList['FIRST NAME']} ${onTherapyList['LAST NAME']}`,
                            RxDate: this.moment(`${onTherapyList.RxDate}`).format('YYYY-MM-DD'),
                            nextDoseDate: this.moment(`${onTherapyList.RxDate}`)
                                .add(`${onTherapyList.Days}`, 'days')
                                .format('YYYY-MM-DD'),
                            today: this.moment().format('YYYY-MM-DD'),
                            daysElapsed: this.moment(`${onTherapyList.RxDate}`),
                            days: parseFloat(
                                this.moment(`${onTherapyList.RxDate}`).add(`${onTherapyList.Days}`, 'days').diff(this.moment(), 'days'),
                            ),
                            daysUntilDue: parseFloat(
                                this.moment(`${onTherapyList.RxDate}`).add(`${onTherapyList.Days}`, 'days').diff(this.moment(), 'days'),
                            ),

                            // // daysUntilDue: this.today.dif(this.nextDoseDate, 'days')
                            // daysUntilDue: this.nextDoseDate.dif(this.today, "days", false)
                        }))
                    })
                    .then(PatientIDs => {
                        this.patientsList = uniqBy(PatientIDs, 'PatientID').map(patientInfo => ({
                            ...patientInfo,
                            name: `${patientInfo['FIRST NAME']} ${patientInfo['LAST NAME']}`,
                        }))

                        // return this.$root.$system.DBAdapterConn.DBQuery(
                        //   query.candidates[DBType].patientsOnEZ.replace(
                        //     "$PatientIDs",
                        //     this.patientsList.map((row) => row.PatientID).join(",")
                        //   )
                        // );
                    })
                    .then(PatientIDs => {
                        PatientIDs = uniqBy(PatientIDs, 'PatientID')

                        return this.$root.$system.DBAdapterConn.DBQuery(
                            query.candidates[DBType].patientsOnStatins.replace(
                                '$PatientIDs',
                                PatientIDs.map(row => row.PatientID).join(','),
                            ),
                        )
                    })
                    .then(Patients => {
                        Patients = uniqBy(Patients, 'PatientID')

                        // FIXME to filter down
                        // let todaysDate = this.$moment();
                        // // filter our patients that are less 3 months compliant
                        // Patients = Patients.filter( async (dbResults) => {
                        //   let [medicationName, medicationStr ] = dbResults.Medication.match(/([A-Z\s])+\-\d\d\w\w/)[0].split('-');
                        //   let results = await this.$root.$system.DBAdapterConn.getPatientMedicationHistory(dbResults.PatientID, medicationName, medicationStr);
                        //   return results.some(record => {
                        //       return (
                        //         todaysDate.diff(this.$moment(record["RxDate"]), "days") >= 90
                        //       );
                        //     });
                        //   });
                        // // End filter

                        return this.$root.$system.DBAdapterConn.DBQuery(
                            query.candidates[DBType].patientsInfo.replace(
                                '$PatientIDs',
                                Patients.map(row => row.PatientID).join(','),
                            ),
                        ).then(results => {
                            // group by Insurance name
                            // FIXME ......wot...????? refactor it....
                            results = results.reduce((rows, patient, index) => {
                                const foundIndex = rows.findIndex(row => row.PatientID === patient.PatientID)

                                // FIXME this isn't pulling insurances correctly
                                const patientsInsuranceProvider = this.$store.state.Insurance.providers.find(insurance => {
                                    let carrierIdCond = true
                                    if (patient.CarrierID && insurance.carrierId) {
                                        carrierIdCond = patient.CarrierID == insurance.carrierId
                                    }

                                    return patient.PlanCode == insurance.planCode && carrierIdCond
                                })

                                let insuranceProvider
                                if (patientsInsuranceProvider) {
                                    insuranceProvider = {
                                        apikey: patientsInsuranceProvider.id,
                                        code: patientsInsuranceProvider.code,
                                        name: patientsInsuranceProvider.name,
                                        PlanCode: patient.PlanCode,
                                        CarrierID: patient.CarrierID,
                                        GroupID: patient.GroupID,
                                        PlanID: patient.PlanID,
                                        Relationship: patient.Relationship,
                                        PatientCode: patient.PatientCode,
                                    }
                                }

                                // FIXME should be part of the insurance condition or black list filter callbacks
                                const isBlackListed = patient.CarrierID === 'S'
                  || (patient.CarrierID === '32' && patient.PlanCode === 'ESI')
                  || (patient.CarrierID === '80' && patient.PlanCode === 'AS')

                                // FIXME yeah...........long night
                                if (foundIndex === -1) {
                                    const insuranceArray = []

                                    // we only want ppl with no CarriesID = S
                                    if (!isBlackListed && insuranceProvider) {
                                        insuranceArray.push(insuranceProvider)
                                    }

                                    // switch it to an array
                                    patient.InsuranceProvider = insuranceArray

                                    rows.push(patient)
                                } else if (!isBlackListed && insuranceProvider) {
                                    // add to the array
                                    rows[foundIndex].InsuranceProvider.push(insuranceProvider)
                                }

                                return rows
                            }, [])

                            return results.map((patientInfo, index) => ({
                                ...patientInfo,
                                name: `${patientInfo['FIRST NAME']} ${patientInfo['LAST NAME']}`,
                                Medication: Patients[index].Medication,
                            }))
                        })
                    })

                // .then(results => {
                //   return Promise.all(results.map( async (patient) => {
                //     let patientProcessDetails = await this.$store.dispatch('getProcessStatus', patient["PatientID"]);

                //     return {
                //       ...patient,
                //       'PROCESS STATUS': patientProcessDetails.status,
                //       'PROGRESS': patientProcessDetails.steps
                //     };
                //   }));
                // })
                    .then(results => {
                        this.isDataPulling = false

                        // this.$store.dispatch("resetPatientData");
                        // console.log("reset!!!")
                        // this.$store.dispatch("resetProcess");
                        // this.$store.dispatch("resetPdfData");
                        // // if lab request was loaded
                        // if (typeof this.$store.getters.LabRecord !== "undefined") {
                        //   this.$store.dispatch("resetLab");
                        // }

                        this.candidatesList = results

                        return results
                    })
            )
        },
        selectPatient(patient) {
            // TODO check middle names?
            const name = patient.Name.split(' ')

            this.patientProfile['FIRST NAME'] = name[0]
            this.patientProfile['LAST NAME'] = name[1]
        },
        selectCandidate(patient) {
            this.loadingPatient = true
            this.$root.$system.DBAdapterConn.getPraluentData(patient.PatientID).then(patientData => {
                this.$store.dispatch('savePatientData', {
                    ...patientData,

                    // add insurance
                    InsuranceProvider: patient.InsuranceProvider,
                })
                this.$store.commit('SET_PATIENT_DATA_V2', {
                    ...this.$store.state.Patient.data,
                })
                this.$store.commit('SET_PATIENT_DATA_V2', {
                    ...this.$store.state.Patient.data,
                })

                this.$router.push({
                    name: 'health-optimization-praluent-form',
                    params: {
                        patientID: patientData.PatientID,
                    },
                })
                this.loadingPatient = false
            })
        },
        downloadPdf(patientInfo, pdfRef) {
            this.$store.dispatch('pdfDownload', {
                pdfRef,
                filename: `${pdfRef.id}-${patientInfo['FIRST NAME'].toLowerCase()}-${patientInfo['LAST NAME'].toLowerCase()}`,
            })
        },
        async loadPatientProfile(stateID) {
            const token = await this.$auth.getTokenSilently()
            try {
                const patientData = await stateService.getStateById(token, stateID)
                this.$store.dispatch('savePatientDataV2', patientData)
                this.$router.push({
                    name: 'health-optimization-praluent-form',
                })
            } catch (err) {
                console.error(err)
            }
        },
        async deleteInProgressPraulentForm(stateID) {
            if (window.confirm('Are you sure you want to delete this Praluent Form?')) {
                const token = await this.$auth.getTokenSilently()
                await stateService.deleteStateById(stateID, token)

                return this.patientInProgressList()
            }
        },
        newPatient() {
            return this.$router.push({
                name: 'health-optimization-praluent-form',
                query: {
                    from: 'optimization-sanofi-praluent',

                    // next: `forms-list-patient`,
                    next: 'praluent',
                    options: {
                        patient: true,
                    },
                },
            })
        },
    },
}
</script>

<style scoped>
.candidate-row {
  cursor: pointer;
}
</style>
