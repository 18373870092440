<template>
  <pg-section>
    <div slot="content">
      <v-text-field
        v-model="searchPatient"
        append-icon="search"
        label="Search patient name"
        single-line
        hide-details
      ></v-text-field>
      <p class="subheading">
        Below is a list of patients who have needs their application processed. they will be taken out once the process is done.
      </p>

      <v-alert
        v-show="!this.loading && !patientsList.length"
        :value="true"
        outlined
        color="success"
        icon="check_circle"
      >
        You inbox is clear
      </v-alert>
      <v-data-table
        :items="patientsList"
        item-key="ID"
        class="pa-0 mb-5 elevation-6"
        :headers="patientsHeaders"
        :search="searchPatient"
        :options.sync="pagination"
        :loading="isLoading"
      >
        <v-progress-linear
          v-show="isLoading"
          slot="progress"
          :indeterminate="true"
        ></v-progress-linear>
        <template
          slot="items"
          slot-scope="props"
        >
          <!-- <tr :active="props.selected" class="patient-row">
                    </tr> -->
        </template>
        <template v-slot:item.name="{item}">
          <strong>
            {{ item['patientFirstName'] }}  {{ item['patientLastName'] }}
          </strong>
        </template>
        <template v-slot:item.patientProgress="{ item }">
          <v-chip class="my-2">
            Sent To Patient
            <v-icon
              v-if="item['payload'].emailToPatient == true"
              color="success"
            >
              {{ icons.mdiCheck }}
            </v-icon>
          </v-chip>
          <v-chip>
            Signed By Patient
            <v-icon
              v-if="item['payload'].signedByPatient == true"
              color="success"
            >
              {{ icons.mdiCheck }}
            </v-icon>
          </v-chip>
        </template>
        <!-- <template v-slot:item.signed="{ item }" >
                </template> -->
        <template v-slot:item.doctorProgress="{ item }">
          <v-chip class="my-2">
            Sent To Doctor
            <v-icon
              v-if="item['payload'].faxedToDoctor == true"
              color="success"
            >
              {{ icons.mdiCheck }}
            </v-icon>
          </v-chip>
          <v-chip>
            Signed By Doctor
            <v-icon
              v-if="item['payload'].signedByDoctor == true"
              color="success"
            >
              {{ icons.mdiCheck }}
            </v-icon>
          </v-chip>
        </template>
        <template v-slot:item.insuranceProgress="{ item }">
          <v-chip class="mt-2">
            Sent to Insurance
            <v-icon
              v-if="item['payload'].sentToInsurance == true"
              color="success"
            >
              {{ icons.mdiCheck }}
            </v-icon>
          </v-chip>
          <v-select
            v-model="item['payload'].insuranceResult"
            class="pt-0"
            :items="insuranceDropdown"
            item-value="text"
            label="Insurance Result"
            single-line
            @input="updateResult(item)"
          ></v-select>
        </template>
      </v-data-table>
    </div>
  </pg-section>
</template>

<script>
import Section from '@/components/common/ui/Layout/Section/Section.vue'

// import Container from "@/components/Forms/common/ui/Layout/Container/Container";
import axios from 'axios'

// import { options } from "@/components/Forms/common/ui/Form/FormIO/config";
import {
    trackService
} from '@/render/api/zarya/index'

import {
    mdiCheck,
} from '@mdi/js'

// import "@/components/Forms/common/ui/Form/FormIO/View";

export default {
    components: {
        'pg-section': Section,

    // "pg-container": Container
    },
    props: ['isLoading'],
    data() {
        return {
            icons: {
                mdiCheck,
            },
            insuranceDropdown: [
                { text: 'Approved' },
                { text: 'Rejected' }
            ],
            loading: true,
            patientsHeaders: [
                {
                    text: 'Patient Last Name',
                    align: 'left',
                    sortable: false,
                    class: 'black--text',
                    width: '12.5%',
                    value: 'patientLastName'
                },
                {
                    text: 'Patient First Name',
                    align: 'left',
                    sortable: false,
                    class: 'black--text',
                    width: '12.5%',
                    value: 'patientFirstName'
                },
                {
                    text: 'Patient Progress',
                    align: 'left',
                    sortable: false,
                    class: 'black--text',
                    width: '25%',
                    value: 'patientProgress'
                },
                {
                    text: 'Doctor Progress',
                    sortable: false,
                    class: 'black--text',
                    width: '25%',
                    value: 'doctorProgress'
                },
                {
                    text: 'Insurance Progress',
                    sortable: false,
                    class: 'black--text',
                    width: '25%',
                    value: 'insuranceProgress'
                }
            ],
            patientsList: [],
            patientData: {},
            searchPatient: '',
            pagination: {
                rowsPerPage: 20
            }
        }
    },

    // FIXME this is poorly designed due to the rushing deadlines of business delivering and conflict
    mounted() {
        this.getPatientList()
        if (this.$route.params.tab == '2') {
            setTimeout(() => {
                this.searchSubmittedPatient()
            }, 5000)
        }
    },
    methods: {
        searchSubmittedPatient() {
            this.searchPatient = this.$route.params.patient
        },
        async getPatientInfo(patientID) {
            return await this.$root.$system.DBAdapterConn.getPraluentData(patientID)
        },
        async getPatientList() {
            const token = await this.$auth.getTokenSilently()
            const pharmacyID = this.$store.getters.PharmacyProfile._id
            this.loading = true
            let actionsList = []
            try {
                actionsList = (await trackService.getActionList({
                    patientID: '',
                    formClass: 'praluentInsurance',
                    token,
                    pharmacyID,
                })).data
            } catch (err) {
                console.error('failed to retrieve data from action list')
            }

            this.patientsList = []
            actionsList.map(async patient => {
                if (patient.status === 'completed') {
                // const patientData = await this.getPatientInfo(patient.patientID);
                    return this.patientsList.push({
                        patientFirstName: patient.patientFirstName,
                        patientLastName: patient.patientLastName,
                        ...patient
                    })
                }
            })
        },
        updateResult(props) {
            const payload = {
                _id: props._id,
                insuranceResult: props.payload.insuranceResult
            }
            axios.post('https://ennafqm9nu9sr4w.m.pipedream.net', { ...payload, })
                .then(res => res)
                .catch(err => err)
        }
    }
}
</script>

<style scoped>
</style>
