var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('pg-section',[_c('div',{attrs:{"slot":"content"},slot:"content"},[_c('v-text-field',{attrs:{"append-icon":"search","label":"Search patient name","single-line":"","hide-details":""},model:{value:(_vm.searchPatient),callback:function ($$v) {_vm.searchPatient=$$v},expression:"searchPatient"}}),_c('p',{staticClass:"subheading"},[_vm._v(" Below is a list of patients who have needs their application processed. they will be taken out once the process is done. ")]),_c('v-alert',{directives:[{name:"show",rawName:"v-show",value:(!this.loading && !_vm.patientsList.length),expression:"!this.loading && !patientsList.length"}],attrs:{"value":true,"outlined":"","color":"success","icon":"check_circle"}},[_vm._v(" You inbox is clear ")]),_c('v-data-table',{staticClass:"pa-0 mb-5 elevation-6",attrs:{"items":_vm.patientsList,"item-key":"ID","headers":_vm.patientsHeaders,"search":_vm.searchPatient,"options":_vm.pagination,"loading":_vm.isLoading},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"items",fn:function(props){return undefined}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('strong',[_vm._v(" "+_vm._s(item['patientFirstName'])+" "+_vm._s(item['patientLastName'])+" ")])]}},{key:"item.patientProgress",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"my-2"},[_vm._v(" Sent To Patient "),(item['payload'].emailToPatient == true)?_c('v-icon',{attrs:{"color":"success"}},[_vm._v(" "+_vm._s(_vm.icons.mdiCheck)+" ")]):_vm._e()],1),_c('v-chip',[_vm._v(" Signed By Patient "),(item['payload'].signedByPatient == true)?_c('v-icon',{attrs:{"color":"success"}},[_vm._v(" "+_vm._s(_vm.icons.mdiCheck)+" ")]):_vm._e()],1)]}},{key:"item.doctorProgress",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"my-2"},[_vm._v(" Sent To Doctor "),(item['payload'].faxedToDoctor == true)?_c('v-icon',{attrs:{"color":"success"}},[_vm._v(" "+_vm._s(_vm.icons.mdiCheck)+" ")]):_vm._e()],1),_c('v-chip',[_vm._v(" Signed By Doctor "),(item['payload'].signedByDoctor == true)?_c('v-icon',{attrs:{"color":"success"}},[_vm._v(" "+_vm._s(_vm.icons.mdiCheck)+" ")]):_vm._e()],1)]}},{key:"item.insuranceProgress",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"mt-2"},[_vm._v(" Sent to Insurance "),(item['payload'].sentToInsurance == true)?_c('v-icon',{attrs:{"color":"success"}},[_vm._v(" "+_vm._s(_vm.icons.mdiCheck)+" ")]):_vm._e()],1),_c('v-select',{staticClass:"pt-0",attrs:{"items":_vm.insuranceDropdown,"item-value":"text","label":"Insurance Result","single-line":""},on:{"input":function($event){return _vm.updateResult(item)}},model:{value:(item['payload'].insuranceResult),callback:function ($$v) {_vm.$set(item['payload'], "insuranceResult", $$v)},expression:"item['payload'].insuranceResult"}})]}}])},[_c('v-progress-linear',{directives:[{name:"show",rawName:"v-show",value:(_vm.isLoading),expression:"isLoading"}],attrs:{"slot":"progress","indeterminate":true},slot:"progress"})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }